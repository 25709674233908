/*
 * @Author: your name
 * @Date: 2021-11-29 11:02:16
 * @LastEditTime: 2021-12-06 16:37:04
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/api/common/index.js
 */
import request from '@/utils/request.js';
export function commonGetUploadParam() {
    return request({
        url: `common/getUploadParam`,
        method: 'get',
    })
}
// 上传到阿里云之后还需要调用新增接口  保存到 数据库
export function templateResourcesAddYtjj(data) {
    return request({
        url: `template/resources/addYtjj`,
        method: 'post', // 请求方式
        data: data, // post参数
    })
}
