/*
 * @Author:  LiuTao
 * @Date: 2021-11-08 10:30:32
 * @LastEditTime: 2021-12-16 10:38:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/utils/request.js
 */
import axios from 'axios';
import {
    getToken
} from './utils'
import { Notify } from 'vant';
const errorCode = {
    '401': '认证失败，无法访问系统资源',
    '403': '当前操作没有权限',
    '404': '访问资源不存在',
    'default': '系统未知错误，请反馈给管理员'
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

const service = axios.create({
    baseURL: '/api/',
    timeout: 30000
})

service.interceptors.request.use(config => {
    // 是否需要设置 token   
    const isToken = (config.headers || {}).isToken === false
    if (getToken() && !isToken) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
}, error => {
    Promise.reject(error)
})


// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default']
    if (code === 401) {
        Notify({
            message: res.data.msg,
            type: 'danger'
        })
        localStorage.removeItem('courseToken');
        localStorage.removeItem('classInfo');
        localStorage.removeItem('schoolData');
        localStorage.removeItem('sendClass');
        location.href = "/#/login"

    }else if(code === 420){
        Notify({
            message: res.data.msg,
            type: 'danger'
        })
        localStorage.removeItem('courseToken');
        localStorage.removeItem('classInfo');
        localStorage.removeItem('schoolData');
        localStorage.removeItem('sendClass');
        location.href = "/#/login"
    }
    
    else if (code === 500) {
        Notify({
            message: msg,
            type: 'danger',
            duration: 5000,
        })
    } else if (code !== 200) {
        Notify({
            message: msg,
            type: 'danger',
        })
        return Promise.reject('error')
    } else {
        return res.data
    }
},
    error => {
        let {
            message
        } = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Notify({
            message: message,
            type: 'danger',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)


export default service