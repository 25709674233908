/*
 * @Author: LiuTao
 * @Date: 2021-11-09 11:30:09
 * @LastEditTime: 2021-12-16 10:32:14
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: /ytjj-mobile/src/utils/utils.js
 */
const OSSSERVE = require("ali-oss");
const STSSERVE = require("ali-oss/lib/sts");
import Cookies from 'js-cookie'
import moment from "moment";
import {
    commonGetUploadParam,
    templateResourcesAddYtjj
} from "@/api/common/index";
let clinet = "";

export function getAliyunConfig() {
    //获取阿里云配置接口
    commonGetUploadParam().then(res => {
        let configInfo = {
            region: "oss-cn-hangzhou", // 地区
            bucket: res.data.bucketName, // 文件夹名字
            accessKeyId: res.data.accessKeyId, // 阿里云的运用id,唯一标识
            accessKeySecret: res.data.accessKeySecret, // 密钥
            stsToken: "", // sts的token写在下面
            policy: res.data.policy, // 同源策略
            roleArn: res.data.roleArn, // sts要用的
            roleSessionName: res.data.roleSessionName, // sts要用的
            endpoint: res.data.endpoint,
            replaceUrl: res.data.replaceUrl, // 原来阿里云的链接
            uploadUrl: res.data.uploadUrl, // 原来阿里云的链接,后端返回的链接,替换阿里云的链接
        };
        let sts = new STSSERVE({
            // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
            accessKeyId: configInfo.accessKeyId, // oss应用id
            accessKeySecret: configInfo.accessKeySecret, // 签名,密钥
        });
        (async () => {
            try {
                let policy = {
                    Version: "1",
                    Statement: [{
                        Effect: "Allow",
                        Action: [
                            "oss:DeleteObject",
                            "oss:ListParts",
                            "oss:AbortMultipartUpload",
                            "oss:PutObject",
                        ],
                        Resource: [
                            "acs:oss:*:*:" + configInfo.bucket, // eston-hangzhou-test1要用当前阿里云注册的地区,其他不用改
                            "acs:oss:*:*:" + configInfo.bucket + "/*", // eston-hangzhou-test1要用当前阿里云注册的地区,其他不用改
                        ],
                    },],
                };
                let token = await sts.assumeRole(
                    configInfo.roleArn,
                    policy, // 自己定义的变量
                    "3600", // sts token使用有效期,900-3600s,单位秒
                    configInfo.roleSessionName
                )
                clinet = new OSSSERVE({
                    region: "oss-cn-hangzhou", // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
                    accessKeyId: token.credentials.AccessKeyId,
                    accessKeySecret: token.credentials.AccessKeySecret,
                    stsToken: token.credentials.SecurityToken,
                    bucket: configInfo.bucket, // 文件夹名字,一个空间
                });

            } catch (e) {
                console.log(e);
            }
        })()

    }).catch(err => {
        console.log(err)
    })
}



export function uploadFile(option, uploadInfo) {

    return new Promise((resolve, reject) => {
        console.log(reject)
        const file = option.file;
        const random_name =
            random_string(6) +
            "_" +
            new Date().getTime() +
            "." +
            file.name.split(".").pop();
        let fileType = file.type; // 当前上传的本地文件类型
        let fileSuffix = file.name.split("."); // 当前上传的本地文件的后缀,那file.name后面的点.的格式即可,如jpg/png
        let fileSuffix2 = fileSuffix[fileSuffix.length - 1]; // 截取获取后面的格式,如jpg或png
        console.log('fileSuffix2', fileSuffix2);
        let fileSuffix3 = file.name.substring(0, file.name.lastIndexOf(".")); // lastIndexOf()返回一个指定的字符串值最后出现的位置,在一个字符串中的指定位置从后向前搜索
        (async () => {
            try {
                await clinet
                    .multipartUpload(random_name, file, {
                        progress: function (p) {
                            uploadInfo.showProgress = true;
                            uploadInfo.process = Math.floor(p * 100);
                        },
                    })
                    .then((res) => {
                        let fileUrl = res.res.requestUrls[0];
                        let ipos = fileUrl.indexOf("?");
                        let newURL =
                            ipos === -1 ? fileUrl : fileUrl.substring(0, ipos); //取后部分

                        templateResourcesAddYtjj({
                            id: "", // id传空,编辑上传时id不可为空
                            name: random_name, // 当前文件的随机命名,不是文件的名字
                            fileName: fileSuffix3.length > 30 ? fileSuffix3.slice(0, 30) : fileSuffix3, // 当前上传的本地文件名字
                            fileSize: file.size, // 当前上传的本地文件大小
                            fileType: 0, // 0 图片  1 视频 2 文档 3 链接 4 音乐-不用传
                            fileSuffix: fileSuffix2, // 当前上传的本地文件后缀
                            fileUrl: newURL, // 当前上传的文件路径,接口获取的
                            privateType: uploadInfo.privateType, //  privateType 传5  type 传5 代表学校资源  privateType 传6  type 传6 代表个人头像资源
                            description: "", // 描述留空即可,接口传要有,   
                            type: uploadInfo.type //7 集团 8 学校 9 人员头像
                        }).then(res => {
                            uploadInfo.fileUrl = newURL;
                            uploadInfo.showProgress = false;
                            uploadInfo.process = 0;
                            uploadInfo.fileName = fileSuffix3;
                            if (
                                fileSuffix2.toLowerCase() == "jpg" ||
                                fileSuffix2.toLowerCase() == "jpeg" ||
                                fileSuffix2.toLowerCase() == "png"
                            ) {
                                // 上传的是图片
                                fileType = "0"; // 0图片 1视频 2文档 3链接 4音乐
                            }
                            if (fileSuffix2 == "mp3") {
                                // 上传的是音频音乐
                                fileType = "4"; // 0图片 1视频 2文档 3链接 4音乐
                            }
                            if (fileSuffix2 == "mp4" || fileSuffix2 =="MOV" || fileSuffix2 =="MP4" || fileSuffix2 =="mov") {
                                // 上传的是视频
                                fileType = "1"; // 0图片 1视频 2文档 3链接 4音乐
                            }
                            if (fileSuffix2 == "pdf") {
                                // 上传的是文档
                                fileType = "2"; // 0图片 1视频 2文档 3链接 4音乐
                            }
                            let info = {
                                description: '',
                                fileSize: file.size,
                                fileSuffix: fileSuffix2,
                                fileType: fileType,
                                id: res.data.id,
                                name: random_name,
                            }
                            resolve({ ...uploadInfo, ...info })
                        })
                    });
            } catch (e) {
                // 请求失败
                console.log(e);
            }
        })()
    })


}


export function getToken() {
    return Cookies.get('token')
}
export function random_string(len) {
    len = len || 32;
    let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz12345678",
        maxPos = chars.length,
        pwd = "";
    for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}
// 获取本周开始结束日期
export function getCurrentWeek(i) {
    // const start = moment().weekday(1).format('YYYYMMDD'); //本周一
    // const end = moment().weekday(7).format('YYYYMMDD'); //本周日
    // return [start, end]
    let weekOfDay = parseInt(moment().format('E'));//计算今天是这周第几天
    let last_monday = moment().subtract(weekOfDay + 7 * i - 1, 'days').format('YYYYMMDD');//周一日期
    let last_sunday = moment().subtract(weekOfDay + 7 * (i - 1), 'days').format('YYYYMMDD');//周日日期
    return [last_monday, last_sunday]
}
// 获取上周开始结束时间
export function getLastWeek(i) {
    let weekOfDay = parseInt(moment().format('E'));//计算今天是这周第几天
    let last_monday = moment().subtract(weekOfDay + 7 * i - 1, 'days').format('YYYYMMDD');//周一日期
    let last_sunday = moment().subtract(weekOfDay + 7 * (i - 1), 'days').format('YYYYMMDD');//周日日期
    return [last_monday, last_sunday]
}
//获取下周开始结束日期
export function getNextWeek(i) {
    let weekOfDay = parseInt(moment().format('E'));//计算今天是这周第几天
    let next_monday = moment().add((7 - weekOfDay) + 7 * (i - 1) + 1, 'days').format('YYYYMMDD');//周一日期
    let next_sunday = moment().add((7 - weekOfDay) + 7 * i, 'days').format('YYYYMMDD');//周日日期
    return [next_monday, next_sunday]
}
export function downloadIamge(url, name) { //下载图片
    let src = url;
    let canvas = document.createElement('canvas');
    let img = document.createElement('img');
    img.setAttribute("crossOrigin", 'Anonymous');
    img.onload = function (e) {
        console.log(e);
        canvas.width = img.width;
        canvas.height = img.height;
        let context = canvas.getContext('2d');
        context.drawImage(img, 0, 0, img.width, img.height);
        let url = canvas.toDataURL();
        const a = document.createElement('a');
        const event = new MouseEvent('click');
        a.download = `${name}.jpg`;
        a.href = url;
        a.dispatchEvent(event);
    }
    img.src = src;
}